.nav {
       height: 0px;
}

.menu-item {
       color: white;
       width: 100%;
       margin: 0.5rem 0rem;
       text-align: center;
       &:hover {
              color: #0d6efd;
       }

       &.bottom {
              position: absolute;
              bottom: 0.5rem;
              left: 0rem;
       }

}