.orders {
    max-width: 100vw;

    .list-header {
        padding: 0rem 0.25rem 0.25rem;
        > div {
            border: 1px solid #cccccc;
            padding: 0rem 0.25rem;
            
            &:not(:last-child) {
                margin-bottom: 0.2rem;
            }
        }

        .search-term {
            .search-input {
                border: none;
                width: 100%;
                outline: none !important;
            }
        }
    }
    .batch-actions {
        padding: 0rem 0.25rem 0.25rem;

        .link-button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            text-decoration: underline;
            display: inline;
            margin: 0;
            padding: 0;
            margin-left: 0.25rem;
            color: #0d6efd;

            &:hover {
                color: #0a58ca;
            }
        }
    }

    .order-list {
        display: flex;
        flex-direction: column;

        .order-row {
            padding: 0.2rem;
            background-color: #f4f4f4;
            display: flex;
            border: 1px solid #c1c1c1;

            &:nth-child(2n) {
                background-color: white;
            }

            .form-check {
                display: flex;
                align-items: center;
                width: 2rem;
                justify-content: center;
                cursor: pointer;

                input {
                    margin: 0px;
                    cursor: pointer;
                }
            }
            .product-image {
                border: 1px solid #c1c1c1;
                background-color: white;
                height: 4.4rem;
                width: 4.4rem;
                display: flex;
                justify-content: center;
                overflow: hidden;

                .product-image-wrapper {
                    
                    height: 100%;
                }

                object, img {
                    height: 100%;
                }
            }

            .product-details {
                padding-left: 0.5rem;
                font-size: 12px;
                width: 100%;

                .inner-details {
                    display: flex;
                    flex-direction: row;
                    
                    .order-by {
                        display: flex;
                        margin-right: 0.5rem;
                        width: 100%;

                        .list-category {
                            width: 100%;
                        }
                    }

                    .date-and-price {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .paid-picker {
                            .paid-label {
                                margin-right: 0.5rem;
                            }
                            display: flex;
                            align-items: center;

                            input {
                                width: 4.5rem;
                            }
                        }
                    }
                }


            }
        }
    }

    .list-footer {
        margin: 0.5rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination {
        margin-bottom: 0rem;

        :global(.page-link) {
            padding: 0.3rem;
        }
    }
    
}